@import 'font/fonts';
@import 'global_helper_wl_campaign'; // Variables.scss & mixins.scss
@import 'base/common_wl_campaign'; // Globale Styles (z.B normalisierungen)
@import 'modules/banner';
@import 'modules/form';
@import 'modules/image-gallery';
@import 'modules/wl-footer';
@import 'modules/wl-glossar';
@import 'modules/wl-iframe';
@import 'modules/wl-image';
@import 'modules/wl-infobox';
@import 'modules/wl-keyvisual';
@import 'modules/wl-newsletter-teaser';
@import 'modules/wl-quote';
@import 'modules/wl-richtext';
@import 'modules/wl-share';
@import 'modules/wl-tiles_view';
@import 'modules/wl-video';

.header {
  background-color: $area-color-1;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  height: 90px;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;

  img {
    height: 90px;
  }
}

.main-content {
  min-height: calc(100vh - 330px);
  overflow: hidden;
  padding-bottom: 3rem;
}

.content-column {
  margin: 0 auto;
  padding: 0 20px;

  @media #{$bp-medium} {
    max-width: 738px;
    padding: 0 20px;
    width: 100%;
  }

  @media #{$bp-large} {
    max-width: 960px;
  }

  @media #{$bp-extra-large} {
    max-width: 1140px;
  }
}

.content-column-full {
  margin: 0;
  padding: 0;
  width: 100%;
}

.button {
  background-color: transparent;
  border: 1px solid $text-color-4;
  color: $text-color-4;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 2rem;
  text-decoration: none;

  i {
    margin-right: 0.25rem;
  }

  &.inverse {
    border-color: $text-color-1;
    color: $text-color-1;
  }
}

.detail-info-header {
  margin-top: 147px;

  .info-header-label {
    color: $text-color-1;
    display: inline-block;
    font-size: 14px;
    height: 28px;
    line-height: 29px;
    margin-bottom: 10px;
    margin-right: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: relative;
    text-transform: uppercase;
  }

  .info-header-tags {
    margin: 11px 0 23px;
    padding: 0;

    li {
      border-right: 1px solid $text-color-4;
      color: $text-color-4;
      display: inline-block;
      margin-right: 10px;
      padding-right: 10px;

      &:last-child {
        border: 0;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}
