.richtext-module {
  padding-top: 30px;

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  .text-content {
    font-size: 16px;
    line-height: 28px;

    p {
      font-size: 16px;
      line-height: 28px;
    }

    strong {
      font-weight: 600;
    }
  }
}

.wasser-laeuft-subtitle {
  color: $text-color-4;
  font-size: 20px !important;
  line-height: 32px !important;
}
