body {
  background-color: $area-color-1;
  color: $text-color-2;
  font-family: $font-family-1;
  font-weight: 300;
  margin: 0;
  padding-top: 90px;
}

.hidden {
  display: none !important;
}

h2 {
  color: $text-color-4;
  display: inline-block;
  font-size: 30px;
}

h3 {
  color: $text-color-4;
  display: inline-block;
  font-size: 18px;
}

.btn-cta {
  background-color: $cta-background-color;
  border: 0;
  color: $cta-text-color;
  font-size: 14px;
  font-weight: 300;
  height: 36px;
  line-height: 32px;
  padding-left: 2rem;
  padding-right: 2rem;

  &:focus {
    outline: none;
  }
}
