.infobox-module {
  background-color: #e8eff6;
  color: $text-color-4;
  margin-top: 30px;
  padding: 1rem 1.5rem;

  @media #{$bp-large} {
    padding: 2rem 4rem;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  .text-content {
    font-size: 16px;
    line-height: 28px;

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}
