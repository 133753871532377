.video-container {
  height: 0;
  margin-top: 30px;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 0;
  position: relative;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.video-preview-wrapper {
  max-width: 750px;
  width: 100%;

  .video-preview-cookie-overlay {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;

    .video-preview-cookie-message {
      background-color: rgba(50, 50, 50, 0.75);
      border-radius: 1rem;
      padding: 1rem;
      text-align: center;

      @media #{$bp-medium} {
        padding: 3rem;
      }

      p {
        font-size: 16px;

        @media #{$bp-medium} {
          font-size: 20px;
        }
      }
    }
  }
}

.video-preview {
  height: 100%;
  width: 100%;
}
