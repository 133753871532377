.share-module {
  margin-top: 30px;

  .share-label {
    color: $text-color-4;
    font-size: 16px;
    height: 28px;
    line-height: 28px;
  }

  a {
    color: $text-color-4;
    font-size: 48px;
    text-decoration: none;
  }
}
