.quote-module {
  margin-top: 30px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @media #{$bp-large} {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .quote-wrapper {
    display: flex;
    flex-flow: column;

    @media #{$bp-large} {
      flex-flow: row;
    }
  }

  .quote-image {
    flex: 2;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .quote-content {
    color: $text-color-4;
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: center;

    @media #{$bp-large} {
      padding-left: 2rem;
    }
  }

  .quote-text {
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;

    padding-bottom: 1rem;

    @media #{$bp-large} {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 2rem;
    }
  }

  .quote-author {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }

  .quote-subtitle {
    font-size: 16px;
    line-height: 18px;
  }

  .chirality-right {
    &.quote-wrapper {
      @media #{$bp-large} {
        flex-flow: row-reverse;
      }
    }

    .quote-content {
      @media #{$bp-large} {
        padding-right: 2rem;
        text-align: right;
      }
    }
  }
}
