.tiles-view {
  margin-top: 30px;

  .tiles-view-results {
    display: flex;
    flex-flow: row wrap;

    .tiles-view-results-tile {
      background-color: #f0eeef;
      color: $text-color-1;
      display: flex;
      flex-direction: column;
      height: auto;
      margin-bottom: 15px;
      margin-top: 15px;
      width: 100%;

      &.media-tile {
        color: $text-color-4;

        a {
          color: $text-color-4;
        }
      }

      &:nth-child(3n) {
        @media #{$bp-large} {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @media (min-width: 577px) and (max-width: 991px) {
          margin-right: 0;
        }
      }

      .tiles-view-results-tile-image {
        width: 100%;
      }

      @media #{$bp-small} {
        margin-right: 20px;
        width: calc((100% / 2) - 10px);
      }

      @media #{$bp-large} {
        margin-right: 30px;
        width: calc((100% / 3) - 20px);
      }

      .tiles-view-results-tile-content-wrapper {
        padding: 1rem 1rem 0;
      }

      .more-link {
        color: $text-color-1;
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 1.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        text-decoration: underline;
      }

      .tile-label {
        border: 0.5px solid $text-color-1;
        box-sizing: border-box;
        display: inline-block;
        font-size: 10px;
        letter-spacing: 0.83px;
        line-height: 17px;
        margin-bottom: 0.75rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-transform: uppercase;
      }

      .tile-headline {
        font-size: 20px;
        line-height: 24px;
      }

      .tile-teaser {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .filter-category-headline {
    font-size: 18px;
  }

  .tiles-view-filter-tags {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
  }

  .tiles-view-filter-folders {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    margin-top: 10px;

    .folder {
      border: 1px solid $text-color-2;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      height: 28px;
      line-height: 29px;
      margin-bottom: 10px;
      margin-right: 1rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      position: relative;
      text-transform: uppercase;

      i {
        display: none;
      }

      &.unchecked {
        background-color: transparent !important;
      }

      &.checked {
        color: $text-color-1;
        padding-left: 1rem;
        padding-right: 2rem;

        i {
          display: inline-block;
          position: absolute;
          right: 0.5rem;
          top: calc((100% - 1rem) / 2 + 1px);
        }
      }

      input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
      }
    }
  }

  .tiles-view-filter {
    background-color: #f3f7fb;
    margin-bottom: 30px;

    padding: 1rem 1.5rem;

    @media #{$bp-large} {
      padding: 2rem 4rem;
    }
  }

  .tiles-view-loading {
    font-size: 48px;
    padding: 2rem;
    text-align: center;
  }

  .tiles-view-load-more {
    padding: 2rem;
    text-align: center;
  }

  .tiles-view-no-results {
    font-size: 1.25rem;
    padding: 3rem;
    text-align: center;
  }

  .checkbox-container {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 22px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-right: 2rem;
    padding-left: 30px;
    position: relative;
    user-select: none;

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .checkmark {
      background-color: transparent;
      border: 1px solid #979797;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }

    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    input:checked ~ .checkmark {
      background-color: #11254e;
    }

    input:checked ~ .checkmark::after {
      display: block;
    }

    .checkmark::after {
      content: '';
      display: none;
      position: absolute;
    }

    .checkmark::after {
      border: solid $text-color-1;
      border-width: 0 3px 3px 0;
      height: 10px;
      left: 6px;
      top: 2px;
      transform: rotate(45deg);
      width: 5px;
    }
  }
}
