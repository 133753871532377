.glossar-search-form {
  .btn-cta {
    background-color: #f49f3d;
    cursor: pointer;
  }

  input[type='text'] {
    background-color: $area-color-1;
    border: 1px solid $area-color-1;
    font-size: 14px;
    height: 32px;
    margin-right: 15px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: 150px;

    @media #{$bp-small} {
      width: 200px;
    }

    @media #{$bp-medium} {
      margin-right: 30px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 400px;
    }

    &:focus {
      outline: none;
    }

    &.error {
      background-color: $validation-color-bg;
      border-color: $validation-color;
    }
  }
}

.glossar-search-module {
  input[type='text'] {
    border: 1px solid #979797;
  }
}

.glossar-search-teaser {
  background-color: $area-color-3;
  color: $text-color-1;
  margin-top: 30px;
  padding: 1rem 1.5rem;

  @media #{$bp-large} {
    padding: 2rem 4rem;
  }

  h2 {
    color: $text-color-1;
    margin: 0;
  }

  a {
    color: $text-color-1;
    font-size: 14px;
  }

  .form-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
  }
}

.glossar-search-teaser-text {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.glossar-navigation {
  margin-top: 30px;

  ul {
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      display: block;
      margin-bottom: 1rem;
      margin-right: 2rem;

      @media #{$bp-medium} {
        display: inline-block;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  a {
    color: $text-color-4;
    font-size: 18px;
    line-height: 22px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.sticky {
    background-color: #fff;
    /*border-bottom: 1px solid rgba(0, 0, 0, 0.75);*/
    margin-top: 0;
    padding-top: 15px;
    position: fixed;
    top: 90px;
    width: 100%;

    @media #{$bp-medium} {
      max-width: 738px;
    }

    @media #{$bp-large} {
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      max-width: 1140px;
    }
  }
}

.glossar-section {
  margin-top: 3rem;

  .glossar-section-headline {
    color: $text-color-4;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 1rem;
  }
}

.glossar-entries {
  .glossar-entry {
    mark {
      background-color: lighten($area-color-3, 25%);
      color: $text-color-1;
    }

    a {
      color: $text-color-4;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .glossar-entry-header {
      align-items: center;
      border-bottom: 1px solid $text-color-1;
      color: $text-color-2;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      font-size: 18px;
      font-weight: bold;
      height: 40px;
      justify-content: space-between;
      line-height: 40px;

      .open {
        display: none;
      }

      .close {
        display: inline-block;
      }
    }

    .glossar-entry-content {
      padding-bottom: 1.5rem;
    }

    &.closed {
      .open {
        display: inline-block;
      }

      .close {
        display: none;
      }

      .glossar-entry-content {
        display: none;
      }

      .glossar-entry-header {
        border-bottom: 1px solid $text-color-2;
        font-weight: 300;
      }
    }

    .image-module {
      img {
        width: auto !important;
      }
    }
  }
}
