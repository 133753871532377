//Font Families
$font-family-1: Helvetica, Arial, Sans-Serif;
$font-family-2: 'Myriad Pro';

//Media Queries
$bp-small: '(min-width: 576px)';
$bp-medium: '(min-width: 768px)';
$bp-large: '(min-width: 992px)';
$bp-extra-large: '(min-width: 1220px)';

//Validation Red
$validation-color: rgba(200, 46, 45, 1);
$validation-color-bg: #e1c6c6;

//Text Colors
$text-color-1: #fff;
$text-color-2: #000;
$text-color-3: #b52440;
$text-color-4: #1968ab;

$cta-text-color: #fff;
$cta-background-color: #b52440;

//Shade & Area Colors
$transparent: rgba(0, 0, 0, 0);
$area-color-1: #fff;
$area-color-1-90: rgba(255, 255, 255, 0.95);
$area-color-2: #f59d3f;
$area-color-2-90: rgba(245, 157, 63, 0.9);
$area-color-3: rgb(37, 106, 173);
$area-color-3-90: rgba(37, 106, 173, 90);
$area-color-4: #f0efed;
$area-color-5: #1968ab;
$area-color-5-90: rgba(25, 104, 171, 90);
$area-color-6: #000;
$area-color-6-65: rgba(0, 0, 0, 0.65);
$area-color-6-50: rgba(0, 0, 0, 0.5);
$area-color-6-40: rgba(0, 0, 0, 0.4);
$area-color-7: #a7a7a7;
$area-color-8: #009ee2;
