.newsletter-teaser {
  background-color: #00a2d3;
  background-image: url('/ldew/static/Box_Welle.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
  text-align: center;
  width: 100%;

  .teaser-text {
    color: $text-color-1;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;

    @media #{$bp-large} {
      max-width: 600px;
    }
  }
}
