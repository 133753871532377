.keyvisual-module {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .teaser-wrapper {
    color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-flow: column;
    font-size: 16px;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    left: 0;
    line-height: 20px;
    position: absolute;
    top: 0;
    width: 100%;

    @media #{$bp-large} {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .copyright {
    background-color: rgba(50, 50, 50, 0.75);
    bottom: 0;
    color: $text-color-1;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 0;
    z-index: 10;

    a {
      color: $text-color-1;
      text-decoration: none;
    }
  }
}
