.image-module {
  margin-top: 30px;

  img {
    max-width: 100%;
    width: 100%;
  }

  .caption {
    color: $text-color-4;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-top: 8px;
  }
}
